import Resizer from "react-image-file-resizer";
const imageResize = (file, options) => new Promise((resolve) => {
    console.log("Resizer.imageFileResizer:", Resizer.imageFileResizer);
    Resizer.imageFileResizer(file, (options === null || options === void 0 ? void 0 : options.maxWidth) || 1000, (options === null || options === void 0 ? void 0 : options.maxHeight) || 1000, (options === null || options === void 0 ? void 0 : options.compressFormat) || "JPEG", (options === null || options === void 0 ? void 0 : options.quality) || 80, (options === null || options === void 0 ? void 0 : options.rotation) || 0, (uri) => resolve(uri), "file");
});
const getMediaStream = async (constraints) => {
    if (!constraints)
        constraints = { video: true, audio: true };
    return window.navigator.mediaDevices.getUserMedia(constraints);
};
const getVideoTracks = (video) => {
    if (!video.srcObject && !video.src)
        return undefined;
    const mediaStream = (video.srcObject ||
        // @ts-ignore
        video.captureStream());
    const videoTracks = mediaStream.getVideoTracks();
    return videoTracks;
};
const getAudioTracks = (video) => {
    if (!video.srcObject && !video.src)
        return undefined;
    const mediaStream = (video.srcObject ||
        // @ts-ignore
        video.captureStream());
    const audioTracks = mediaStream.getAudioTracks();
    return audioTracks;
};
const takePhoto = async (video) => {
    if (!video.srcObject && !video.src)
        return undefined;
    const videoTracks = getVideoTracks(video);
    // @ts-ignore
    const imageCapture = new ImageCapture(videoTracks[0]);
    const blob = await imageCapture.takePhoto();
    const objectURL = URL.createObjectURL(blob);
    return objectURL;
};
const getImageBitmap = async (video) => {
    const videoTracks = getVideoTracks(video);
    // @ts-ignore
    const imageCapture = new ImageCapture(videoTracks[0]);
    return imageCapture.grabFrame();
};
const setImageBitmapToCanvas = (imageBitmap, canvas) => {
    if (!canvas)
        canvas = document.createElement("canvas");
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    canvas.getContext("2d").drawImage(imageBitmap, 0, 0);
    return canvas;
};
const getCanvasImage = async (canvas) => {
    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            resolve(blob);
        });
    });
};
const grabFrame = async (video) => {
    if (!video.srcObject && !video.src)
        return undefined;
    const imageBitmap = await getImageBitmap(video);
    const canvas = setImageBitmapToCanvas(imageBitmap);
    const blob = await getCanvasImage(canvas);
    const objectURL = URL.createObjectURL(blob);
    return objectURL;
};
const recordVideo = () => { };
const recordAudio = () => { };
const videoHandler = async (event) => {
    const video = event.target;
    if (video.srcObject || video.src) {
        console.log("video.paused:", video.paused);
        if (video.paused) {
            video.play();
        }
        else {
            video.pause();
        }
    }
    else {
        video.srcObject = await getMediaStream();
    }
    video.addEventListener("paused", () => {
        console.log("Paused");
    });
    video.addEventListener("playing", () => {
        console.log("Playing");
    });
};
export { imageResize, videoHandler, takePhoto, grabFrame, getImageBitmap, getMediaStream, getAudioTracks, getVideoTracks, recordVideo, recordAudio, };
