import "@babel/polyfill";
import * as tf from "@tensorflow/tfjs";
import * as mobilenetModule from "@tensorflow-models/mobilenet";
import * as knnClassifier from "@tensorflow-models/knn-classifier";
const models = {
    knn: undefined,
    mobilenet: undefined,
};
const startTensorflow = async () => {
    console.log("Loading models...");
    models.knn = knnClassifier.create();
    models.mobilenet = await mobilenetModule.load();
    console.log("models loaded");
};
startTensorflow();
// Get tensor3D from image data from pixels
const getTensor3D = (pixels) => tf.browser.fromPixels(pixels);
// 'conv_preds' is the logits activation of MobileNet.
const infer = (img, mobilenet) => mobilenet.infer(img, true);
const train = (pixels, label) => {
    // Get tensor3D from image data from pixels element
    const tensor3D = getTensor3D(pixels);
    const logits = infer(tensor3D, models.mobilenet);
    // Add currenttensor3D from pixels to classifier
    // label: button.name
    models.knn.addExample(logits, label);
    return models.knn;
};
const predict = async (pixels) => {
    const tensor3D = getTensor3D(pixels);
    const numClasses = models.knn.getNumClasses();
    if (numClasses > 0) {
        // If classes have been added run predict
        const logits = infer(tensor3D, models.mobilenet);
        // K value for KNN
        const TOPK = 10;
        const result = await models.knn.predictClass(logits, TOPK);
        // The number of examples for each class
        const exampleCount = models.knn.getClassExampleCount();
        // res.confidences[i] * 100;
        // Dispose tensor3D when done
        tensor3D.dispose();
        if (logits != null) {
            logits.dispose();
        }
        return result;
    }
};
export { train, predict };
