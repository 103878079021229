import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/performance";
import "firebase/analytics";
import "firebase/messaging";
import "firebase/storage";
import "../window";
// store
import { get } from "svelte/store";
import { store } from "../store";
const db = get(store);
// initialize firebase app. required as first step
firebase.initializeApp(db.databases.firebase.config);
// get the firebase auth object
const auth = firebase.auth();
const firestore = firebase.firestore();
const firestoreSettings = () => {
    // cache-----------------------------------
    firestore.settings({
        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
        merge: true,
    });
    firestore.enablePersistence().catch((err) => {
        if (err.code == "failed-precondition") {
            console.log("Multiple tabs open, persistence can only be enabled in one tab at a a time.");
        }
        else if (err.code == "unimplemented") {
            console.log("The current browser does not support all of the features required to enable persistence");
        }
    });
    // Subsequent queries will use persistence, if it was enabled successfully
    // The default cache size threshold is 40 MB. Configure "cacheSizeBytes"
    // for a different threshold (minimum 1 MB) or set to "CACHE_SIZE_UNLIMITED"
    // to disable clean-up.
    // cache-----------------------------------
    // https://firebase.google.com/docs/firestore/manage-data/enable-offline
};
// google sign-in
// https://firebase.google.com/docs/auth/web/google-signin
const googleProvider = new firebase.auth.GoogleAuthProvider();
// Optional: Specify additional OAuth 2.0 scopes that you want to request from the authentication provider.
// To add a scope, call addScope
// provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
const signIn = () => {
    // redirect to Googles login page
    // auth.signInWithRedirect(googleProvider);
    // show a login popup without leaving the app
    auth.signInWithPopup(googleProvider)
        .then((result) => {
        const credential = result.credential;
        console.log("credential:", credential);
        // This gives you a Google Access Token. You can use it to access the Google API.
        const token = credential.accessToken;
        console.log("token:", token);
        // The signed-in user info.
        const user = result.user;
        console.log("user:", user);
    })
        .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        console.log("errorCode:", errorCode);
        const errorMessage = error.message;
        console.log("errorMessage:", errorMessage);
        // The email of the user's account used.
        const email = error.email;
        console.log("email:", email);
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential;
        console.log("credential:", credential);
        // ...
    });
};
// form on:submit={signInWithEmailAndPassword}
const signInWithEmailAndPassword = (event) => {
    event.preventDefault();
    const target = event.target;
    const { email, password } = target.elements;
    firebase
        .auth()
        .signInWithEmailAndPassword(email.value, password.value)
        .then((userCredential) => {
        const user = userCredential.user;
        console.log("Signer in user:", user);
        email.value = "";
        password.value = "";
    })
        .catch((error) => {
        console.log("signInWithEmailAndPassword ERROR", error);
    });
};
const signOut = () => {
    if (window.confirm("Log out?")) {
        // logout promise. clear firebase auth cookies, etc
        auth.signOut()
            .then(() => {
            console.log("Sign-out successful.");
        })
            .catch((error) => {
            console.log("SignOut Error", error);
        });
    }
};
auth.onAuthStateChanged(async (auth) => {
    // if user is not logged in the auth will be null
    if (auth) {
        console.log("logged in auth:", auth);
        db.user = {
            name: auth.displayName,
            email: auth.email,
            emailVerified: auth.emailVerified,
            phone: auth.phoneNumber,
            anonymous: auth.isAnonymous,
            image: auth.photoURL,
            type: "admin",
        };
        db.data[db.navigation.hash] = await getAllData(db.navigation.hash);
        store.set(db);
    }
    else {
        console.log("not logged in:", auth);
        // auth: null
        db.user = null;
        store.set(db);
    }
});
const analytics = () => firebase.analytics();
const getCollection = (collectionName) => firestore.collection(collectionName);
const setCollectionLocal = (collectionName) => {
    const data = getCollection(collectionName);
    const db = get(store);
    db.data[collectionName] = data;
    store.set(db);
};
const getCollectionGroup = (collectionName) => firestore.collectionGroup(collectionName);
const getReference = (collectionName, id) => {
    const collection = getCollection(collectionName);
    return collection.doc(id);
};
const getDocs = async (collectionName) => {
    const collection = getCollection(collectionName);
    return collection.get();
};
const getAllData = async (collectionName) => {
    const docs = await getDocs(collectionName);
    console.log("docs:");
    console.dir(docs);
    const data = {};
    docs.forEach((doc) => {
        data[doc.id] = doc.data();
    });
    return data;
};
const getData = async (collectionName, id) => {
    const ref = getReference(collectionName, id);
    const doc = await ref.get();
    return doc.exists ? doc.data() : undefined;
};
const setData = async (collectionName, data, id) => {
    const ref = getReference(collectionName, id ? id : undefined);
    console.log("ref:", ref);
    const doc = await ref.get();
    if (doc.exists) {
        ref.update(data);
    }
    else {
        ref.set(data);
    }
    return doc.id;
};
export { firebase, signIn, signOut, getCollection, getData, getAllData, setData, };
