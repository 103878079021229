import { writable, get } from "svelte/store";
const store = writable({
    user: undefined,
    data: {},
    navigation: {
        hash: "",
    },
    files: {
        images: {},
    },
    databases: {
        firebase: {
            config: {
                apiKey: "AIzaSyAdvvO6yiB1aoLjiJeBLPoDS2Zxs89qIDY",
                authDomain: "database-305719.firebaseapp.com",
                databaseURL: "https://database-305719-default-rtdb.europe-west1.firebasedatabase.app",
                projectId: "database-305719",
                storageBucket: "database-305719.appspot.com",
                messagingSenderId: "681789933749",
                appId: "1:681789933749:web:8bff5666a83539c9fa96fc",
                measurementId: "G-RZPRTS2D40",
            },
        },
    },
    quantity: 4,
    samples: {},
    images: [],
    predict: {
        active: false,
        interval: 1000
    },
    stream: undefined,
    tensorflow: {
        result: undefined,
    },
});
let oldstore = JSON.parse(JSON.stringify(get(store)));
store.subscribe((newstore) => {
    console.log("oldstore:", oldstore);
    console.log("newstore:", newstore);
});
export { store };
