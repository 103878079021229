import Resizer from "react-image-file-resizer";
import { getAllData } from "../../firebase";
const objectToList = (data) => data ? Object.entries(data) : [];
const listData = (data, key) => data && key
    ? data[key]
        ? [[key, data[key]]]
        : [[key, undefined]]
    : objectToList(data);
const listDataSlug = (data, slug) => data && slug
    ? objectToList(data).filter(([id, e]) => e.slug == slug)
    : objectToList(data);
const selectData = (data) => {
    const list = listData(data).map(([key, e]) => {
        const el = {
            label: e.label || e.name || e.title,
            value: key,
        };
        return el;
    });
    return list;
};
const getFiles = async (data) => {
    for (const key in data) {
        const file = data[key];
        if (file && !file.url) {
            file.url = await URL.createObjectURL(file.file);
            file.type = file.file.type;
        }
    }
};
const resizeImage = (file, options) => new Promise((resolve) => {
    console.log("Resizer.imageFileResizer:", Resizer.imageFileResizer);
    Resizer.imageFileResizer(file, (options === null || options === void 0 ? void 0 : options.maxWidth) || 1000, (options === null || options === void 0 ? void 0 : options.maxHeight) || 1000, (options === null || options === void 0 ? void 0 : options.compressFormat) || "JPEG", (options === null || options === void 0 ? void 0 : options.quality) || 80, (options === null || options === void 0 ? void 0 : options.rotation) || 0, (uri) => resolve(uri), "file");
});
const checkKeyValueinCollection = async (collectionName, key, value) => {
    const collection = await getAllData(collectionName);
    const data = listData(collection);
    return data.some(([id, e]) => e[key] == value);
};
const findKeyValueinCollection = async (collectionName, key, value) => {
    const collection = await getAllData(collectionName);
    const data = listData(collection);
    return data.find(([id, e]) => e[key] == value);
};
const findKeyStartsWithValueinCollection = async (collectionName, key, value) => {
    const collection = await getAllData(collectionName);
    const data = listData(collection);
    return data.filter(([id, e]) => e[key].startsWith(value));
};
export { listData, listDataSlug, selectData, getFiles, resizeImage, checkKeyValueinCollection, findKeyValueinCollection, findKeyStartsWithValueinCollection, };
