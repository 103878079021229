<script lang="ts">
    import {
        videoHandler,
        takePhoto,
        grabFrame,
        getImageBitmap,
    } from "./modules/media";
    import { store } from "./modules/store";
    import { listData } from "./modules/store/utils";
    import { predict, train } from "./modules/tensorflow";

    let video: HTMLVideoElement;

    const submitHandler = (event) => {
        const form: HTMLFormElement = event.target;
        const files: FileList = form.sample.files;

        for (const file of files) {
            if (file.type.includes("video/")) {
                const objectURL = URL.createObjectURL(file);
                video.srcObject = undefined;
                video.src = objectURL;

                video.onload = () => {
                    // const v = document.createElement("video");
                    // v.src = objectURL;
                    // v.play();
                    // video.srcObject = v.captureStream();
                    video.play();
                };
            }
        }
    };

    const startTrain = async (event) => {
        const { name }: HTMLButtonElement = event.target;
        if (!video.src && !video.srcObject) return;
        const sample = video.src ? await getImageBitmap(video) : video;
        const knn = train(sample, name);
        const counts = knn.getClassExampleCount();
        const image = video.src
            ? await grabFrame(video)
            : await takePhoto(video);
        $store.samples[name] = {
            image,
            count: counts[name],
        };
    };

    const startPredict = async (event) => {
        if (!video.src && !video.srcObject) return;
        if (event) {
            $store.predict.active = !$store.predict.active;
        }
        if ($store.predict.active) {
            const sample = video.src ? await getImageBitmap(video) : video;
            $store.tensorflow.result = await predict(sample);
            setTimeout(startPredict, $store.predict.interval);
        }
    };

    const captureFrame = async () => {
        const image = await grabFrame(video);
        if (image) {
            $store.images = [...$store.images, image];
        }
    };

    const setSamples = () => {
        for (let i = 0; i < $store.quantity; i++) {
            if (!$store.samples["s" + i])
                [
                    ($store.samples["s" + i] = {
                        image: undefined,
                        count: 0,
                    }),
                ];
        }
    };
    setSamples();
</script>

<section>
    <video
        bind:this={video}
        playsinline
        autoplay
        controls
        muted
        on:click={videoHandler}
    >
        <track kind="captions" />
    </video>
    <br />
    <div class="controls">
        {#each listData($store.samples) as [id, sample]}
            <div class="control">
                <button name={id} on:click={startTrain}
                    >🎞️ {id} ({sample.count})</button
                >
                {#if sample.image}
                    <br />
                    <img class="sample" src={sample.image} alt={id} />
                {/if}
            </div>
        {/each}
    </div>
    <br />
    <button name="captureframe" on:click={captureFrame}>📷</button>
    <button name="predict" on:click={startPredict}>🔮 {$store.predict.active ? "on" : ""}</button>
    <input type="number" bind:value={$store.quantity} on:change={setSamples} />
</section>
<section class="predict">
    <h3>Predict</h3>
    {#if $store.tensorflow.result}
        <img
            src={$store.samples[$store.tensorflow.result.label].image}
            alt=""
        />
        <p>Label: {$store.tensorflow.result.label}</p>
        <p>classIndex: {$store.tensorflow.result.classIndex}</p>
        {#each listData($store.tensorflow.result.confidences) as [label, confidence]}
            <p>{label}: {confidence * 100}%</p>
        {/each}
    {/if}
</section>
<section class="images">
    <h3>Images</h3>
    <ul>
        {#each $store.images as image}
            <li><img src={image} alt="" /></li>
        {/each}
    </ul>
</section>
<form action="" on:submit|preventDefault={submitHandler}>
    <label for="sample">
        Sample:
        <input
            type="file"
            name="sample"
            accept="image/*,video/*,audio/*"
            multiple
        />
    </label>
    <button type="reset">reset</button>
    <button type="submit">submit</button>
</form>

<style>
    video {
        max-width: 500px;
    }

    img.sample {
        width: 100px;
    }

    .images img {
        max-width: 300px;
    }

    .control {
        background-color: #ccc;
        width: 100px;
        display: inline-block;
        margin: 0 3px;
    }

    .control button {
        width: 100%;
        cursor: pointer;
    }

    .predict img {
        max-width: 300px;
    }
</style>
